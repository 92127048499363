import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { createStore } from 'redux';

// Redux actions
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
const SETTINGS = 'SETTINGS';

// Redux action creators
const loginSuccessAction = (user, jwt) => ({ type: LOGIN_SUCCESS, payload: { user, jwt } });
const loginFailureAction = () => ({ type: LOGIN_FAILURE });
const signOutSuccessAction = () => ({ type: SIGN_OUT_SUCCESS });

// Initial state for Redux
const initialState = {
  isAuthenticated: false,
  jwt: '',
  user: {},
  settings: {},
};

// Redux reducer
function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { 
        ...state, 
        isAuthenticated: true, 
        user: action.payload.user,
        jwt: action.payload.jwt,
        ...action.payload 
      };
    case LOGIN_FAILURE:
      return { ...state };
    case SIGN_OUT_SUCCESS:
      return { 
        ...state, 
        user: '',
        jwt: '',
        isAuthenticated: false 
      };
    case SETTINGS:
      return { ...state, settings: action.payload };
    default:
      return state;
  }
}

// Redux store
const store = createStore(userReducer);

// Contexts for User State and Dispatch
const UserStateContext = createContext();
const UserDispatchContext = createContext();

function UserProvider({ children }) {
  const state = useSelector(state => state);
  const dispatch = useDispatch();

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

// Prop validation for UserProvider
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

function loginSuccess(dispatch, navigate, user, jwt) {
  console.log('loginSuccess():', user);
  dispatch(loginSuccessAction(user, jwt));
  navigate('/', { replace: true });
}

function loginUser(dispatch, login, password, navigate, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  const url = process.env.REACT_APP_SERVER_LOGIN || 'http://localhost:1337/api/auth/local';
  return axios
    .post(url, {
      identifier: login,
      password
    })
    .then((response) => {
      console.log('User profile', response.data.user);
      console.log('User token', response.data.jwt);
      setError(null);
      setIsLoading(false);
      return response.data;
    })
    .catch((error) => {
      dispatch(loginFailureAction());
      setError('*** LOGIN FAILED. Check username and password.');
      setIsLoading(false);
      console.log('An error occurred:', error.response);
      throw error;
    });
}

function registerUser(dispatch, navigate, name, email, password, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);
  const url =
    process.env.REACT_APP_SERVER_REGISTER || 'http://localhost:1337/api/auth/local/register';
  if (!!name && !!email && !!password) {
    axios
      .post(url, {
        username: name,
        email,
        password
      })
      .then((response) => {
        setError(null);
        setIsLoading(false);
        dispatch(loginSuccessAction(response.data.user, response.data.jwt));
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 500);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
        console.log('An error occurred:', error.response);
      });
  } else {
    setError('Registration failed.  Missing username, email or password.');
    setIsLoading(false);
  }
}

function signOut(dispatch) {
  dispatch(signOutSuccessAction());
}

function setStatusAfterSubscription(dispatch) {
  let user = getCookieUser();
  user.is_subscription = 'true';
  dispatch(loginSuccessAction(user, user.jwt));
}

function getCookieUser() {
  try {
    const state = useSelector(state => state);
    return state.user;
  } catch {
    return {};
  }
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  loginSuccess,
  signOut,
  registerUser,
  setStatusAfterSubscription,
  store
};
